import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/router.mjs";
import plugins_analytics_client_ts_mtqVw3YRXk from "/opt/build/repo/plugins/analytics.client.ts";
import plugins_google_recaptcha_ts_rYK9Kziv68 from "/opt/build/repo/plugins/google-recaptcha.ts";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  plugins_analytics_client_ts_mtqVw3YRXk,
  plugins_google_recaptcha_ts_rYK9Kziv68
]