import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'

const components = {
  CustomIconEnvelope: defineAsyncComponent(() => import("/opt/build/repo/components/global/CustomIconEnvelope.vue" /* webpackChunkName: "components/custom-icon-envelope" */).then(c => c.default || c)),
  CustomIconExternalLink: defineAsyncComponent(() => import("/opt/build/repo/components/global/CustomIconExternalLink.vue" /* webpackChunkName: "components/custom-icon-external-link" */).then(c => c.default || c)),
  CustomIconHome: defineAsyncComponent(() => import("/opt/build/repo/components/global/CustomIconHome.vue" /* webpackChunkName: "components/custom-icon-home" */).then(c => c.default || c)),
  CustomIconLoader: defineAsyncComponent(() => import("/opt/build/repo/components/global/CustomIconLoader.vue" /* webpackChunkName: "components/custom-icon-loader" */).then(c => c.default || c)),
  CustomIconPlanet: defineAsyncComponent(() => import("/opt/build/repo/components/global/CustomIconPlanet.vue" /* webpackChunkName: "components/custom-icon-planet" */).then(c => c.default || c)),
  Donut: defineAsyncComponent(() => import("/opt/build/repo/components/global/Donut.vue" /* webpackChunkName: "components/donut" */).then(c => c.default || c)),
  Rootbar: defineAsyncComponent(() => import("/opt/build/repo/components/global/Rootbar.vue" /* webpackChunkName: "components/rootbar" */).then(c => c.default || c)),
  StarRating: defineAsyncComponent(() => import("/opt/build/repo/components/global/StarRating.vue" /* webpackChunkName: "components/star-rating" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
