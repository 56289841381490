import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
    const runtimeConfig = useRuntimeConfig();
    nuxtApp.vueApp.use(VueReCaptcha, {
        siteKey: runtimeConfig.public.SITE_KEY,
        loaderOptions: {
            autoHideBadge: false,
            explicitRenderParameters: {
                badge: 'bottomright',
            },
        },
    });
});