export const appHead = {"meta":[{"name":"viewport","content":"width=375, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"}],"style":[],"script":[{"src":"https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js","defer":true},{"src":"scripts/login-session.js","data-cookie-consent":"strictly-necessary","defer":true}],"noscript":[],"charset":"utf-8","viewport":"width=375, initial-scale=1"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"