import { default as _47opt_47build_47repo_47pages_47contact_46vueMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47index_46vueMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47legal_45terms_46vueMeta } from "/opt/build/repo/pages/legal-terms.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47privacy_46vueMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
export default [
  {
    name: _47opt_47build_47repo_47pages_47contact_46vueMeta?.name ?? "contact",
    path: _47opt_47build_47repo_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "/opt/build/repo/pages/contact.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47contact_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47index_46vueMeta?.name ?? "index",
    path: _47opt_47build_47repo_47pages_47index_46vueMeta?.path ?? "/",
    file: "/opt/build/repo/pages/index.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47legal_45terms_46vueMeta?.name ?? "legal-terms",
    path: _47opt_47build_47repo_47pages_47legal_45terms_46vueMeta?.path ?? "/legal-terms",
    file: "/opt/build/repo/pages/legal-terms.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47legal_45terms_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47legal_45terms_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47legal_45terms_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/legal-terms.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47privacy_46vueMeta?.name ?? "privacy",
    path: _47opt_47build_47repo_47pages_47privacy_46vueMeta?.path ?? "/privacy",
    file: "/opt/build/repo/pages/privacy.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47privacy_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47privacy_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47privacy_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  }
]